export default {
	data() {
		return {
			visitEvents: ['aps', 'pof'],
			stopEvents: ['pba', 'lla', 'fla', 'afa', 'fcl', 'lcl', 'rts', 'ias', 'pbd', 'eas'],
			vesselEvent: ['ata', 'fcl', 'lcl', 'atd'],
			visitEventsPsop: ['apspsop', 'pofpsop'],
			stopEventsPsop: ['pbapsop', 'flapsop', 'afapsop', 'fclpsop', 'lclpsop', 'rtspsop', 'iaspsop', 'pbdpsop', 'llapsop', 'easpsop'],
			stopEventsTerminal: ['afaterminal', 'rtsterminal', 'llaterminal'],
			allEventsName: {
				aps: 'AAP',
				pba: 'ASP',
				fla: 'AAB',
				afa: 'ASM',
				gda: '',
				fcl: 'FCL',
				lhc: '',
				lcl: 'LCL',
				rts: 'RTS',
				ias: 'AAA',
				pbd: 'ASP',
				lla: 'ADB',
				eas: 'ADA',
				pof: 'ACP',
				ata: 'ATA',
				atd: 'ATD'
			},
			allEventsDescription: {
				aps: 'Actual Time of Arrival at Pilot Boarding Place',
				pba: 'Actual Time of Pilot Onboard for Arrival',
				fla: 'Actual Time of Arrival',
				afa: 'Actual Time of Completion of Mooring Service in Arrival process',
				gda: '',
				fcl: 'Actual Time of Start Cargo Operations',
				lhc: '',
				lcl: 'Actual Time of Completion Cargo Operations',
				rts: 'Ready to Sail Pilot Service',
				ias: 'Actual Time of Pilot Onboard for Arrival',
				pbd: 'Actual Time of Pilot Onboard for Departure',
				lla: 'Actual Time of Departure',
				eas: 'Actual Time of Pilot Onboard for Departure',
				pof: 'Actual Time of Pilot Disembarked',
				ata: 'Actual Time of Arrival',
				atd: 'Actual Time of Departure'
			},
			allEventsDescriptionLogs: {
				aps: 'Vessel is at pilot boarding place with pilot arrangements in place',
				pba: 'Pilot puts first foot on vessel at agreed location',
				fla: 'First line is secured around the bollard',
				afa: 'Last line secured and all lines fast',
				gda: '',
				fcl: 'First container engaged with spreader',
				lhc: '',
				lcl: 'Last container disengaged from spreader',
				rts: 'Vessel is seaworthy and ready to receive pilot onboard',
				ias: 'Vessel is initiating the anchorage stop',
				pbd: 'Pilot puts first foot on vessel for departure',
				lla: 'Last line is released off the bollard',
				eas: 'Vessel is finalising the anchorage stop',
				pof: 'Pilot disembarks from vessel and pilot boat clear is reported'
			},
			eventOrigin: {
				aps: 'APBA (PMS)',
				pba: 'APBA (PMS)',
				fla: 'APBA (PMS)',
				afa: 'APBA (PMS)',
				gda: '',
				fcl: 'Terminal (TOS)',
				lhc: '',
				lcl: 'Terminal (TOS)',
				rts: 'APBA (PMS)',
				ias: 'APBA (PMS)',
				pbd: 'APBA (PMS)',
				lla: 'APBA (PMS)',
				eas: 'APBA (PMS)',
				pof: 'APBA (PMS)'
			},
			eventPhase: {
				aps: 'Pilot Process Arrival',
				pba: 'Pilot Berthing Process',
				afa: 'Cargo Operations Preparation',
				fcl: 'Cargo Operations',
				lcl: 'Cargo Operations Closure',
				rts: 'Pilot Unberthing Process',
				pbd: 'Pilot Process Departure'
			},
			stopEventsType: {
				pba: 'ALL',
				fla: 'BERTH',
				afa: 'BERTH',
				gda: '',
				fcl: 'BERTH',
				lhc: '',
				lcl: 'BERTH',
				rts: 'BERTH',
				ias: 'ANCHORED',
				pbd: 'ALL',
				lla: 'BERTH',
				eas: 'ANCHORED'
			}
		};
	}
};
